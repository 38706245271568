/** @format */

import React from 'react';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import Container from 'react-bootstrap/Container';
import FeeCalculator from 'views/Registration/HomePage/FeeCalculator';

export default function SalaryCalculatorPage(props) {
  const { tprop } = props;

  return (
    <>
      <div className='main-container homepage-view'>
        <Header {...props} />
        <Container fluid className='content-wrapper'>
          <FeeCalculator
            {...props}
            tprop={tprop.feeCalculator}
            salaryPage={true}
          />
        </Container>
        <div
          style={{
            height: '40px'
          }}></div>
        <Footer {...props} />
      </div>
    </>
  );
}
